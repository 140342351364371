import React from 'react'
import Hero from '../components/Hero'
import About from './About'
import Servicios from './Servicios';
import More_services from '../More_services'
import CallToAction from '../components/CallToAction'
import Cronograma from '../components/Cronograma'
import Btn_flotante from '../components/Btn_flotante'
import FAQSection from '../components/FAQSection';
import Footer from '../components/Footer';

export default function Home() {
  return (
    <>
    <Hero />
    <About />
    <Servicios />
    <More_services />
    <CallToAction />
    <Cronograma />
    <FAQSection />
    <Btn_flotante />
    <Footer />
    

    </>
  )
}
