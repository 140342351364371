import React, { useState, useEffect } from 'react';
import { Outlet, Link } from "react-router-dom";
import '../css/header.css'

export default function Header() {
  const [scroll, setScroll] = useState(false);
  const [isSubmenu, setIsSubmenu] = useState(null);
  const [isMenu, setIsMenu] = useState(false);


  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 20; // Cambia este valor según tus necesidades
      if (isScrolled !== scroll) {
        setScroll(isScrolled);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scroll]);
    const toggleMenu = () => {
        setIsMenu(!isMenu);
    };
    const toggleSubmenu = (index) => {
        setIsSubmenu((prevState) => (prevState === index ? null : index));
    };

  return (
    <>
       <nav className='menucarga'>
        <div className='container'>
            <div className='nav-menu-carga'>
                <div className='logo_carga'>
                    <Link to="/" className="menu-item" >
                        <div className='menu_img_show_carga'>UNIVERSEPAGE</div>
                    </Link>
                </div>
                <div className='menu_medio'>
                    <ul>
                        
                        <li><a href="#services">SERVICIOS <i class="fi fi-rr-caret-down"></i></a>
                            <ul className='submenu_carga'>
                                <li> <Link to="/pagina-web">Página web</Link></li>
                                <li> <Link to="/tienda-en-linea">Tienda en línea</Link></li>
								<li> <Link to="/desarrollo-web">Desarrollo de software</Link></li>
								<li> <Link to="/hosting-correo">Hosting y correo</Link></li>
								<li> <Link to="/gestion-web">Administración de sitios</Link></li>
								<li> <Link to="/auditoria">Auditoría web</Link></li>
                            </ul>
                        </li>

                        <li className="navbar-item">
                            <Link to="/cotizador">COTIZADOR </Link>
                        </li>
                        
						<li><Link to="/nosotros">NOSOTROS </Link></li>
                        <li><Link to="/blog">BLOG </Link></li>
                        <li><Link to="/contacto" >CONTACTO </Link></li>
						<li className='navbar-item wp-btn'>
							<a href="https://wa.me/5216121970006?text=Me%20interesa%20una%20cotizaci%C3%B3n" target="_blank" rel='noopener noreferrer' aria-label="Contactar por WhatsApp" style={{color:'#fff'}}>
							WhatsApp
							<i class="fa-brands fa-whatsapp"></i></a>                                    
						</li>
                    </ul>
                </div>
                             
            </div>            
        </div>               
     </nav>
     <nav className='menu_responsive_carga'>
        <div className='container'>
            <div className='menu_responsive_carga_content'>
                <div>
                    <Link to="/"><div class="menu_img_show_carga">UNIVERSEPAGE</div></Link>
                </div>
                <div className='btn_menu_responsive_carga'>
                    <button onClick={toggleMenu} aria-label='menu'><i class="fi fi-br-menu-burger"></i></button>
                </div>
            </div>
            
        </div>
        

     </nav>
     {isMenu &&(
                <div className='menu_carga_activo'>
                   
                   <div className='container'>
                   <div className='menu_responsive_carga_content'>
                        <div>
                            <Link href="/"><div class="menu_img_show_carga">UNIVERSEPAGE</div></Link>
                        </div>
                        <div className='btn_menu_responsive_carga'>
                            <button onClick={toggleMenu}><i class="fi fi-br-cross"></i></button>
                        </div>
                    </div>
                  
                   <div className='submenu_list_carga'>
                        <ul>
                            
                                                 
                            <li>
                                <Link onClick={() => toggleSubmenu(1)}> SERVICIOS<i class="fi fi-rr-caret-down"></i></Link> 
								{isSubmenu === 1 &&(
                                <ul className='submenu_item'>
                                     <li> <Link to="/pagina-web" onClick={toggleMenu}>Página web</Link></li>
									<li> <Link to="/tienda-en-linea" onClick={toggleMenu}>Tienda en línea</Link></li>
									<li> <Link to="/desarrollo-web" onClick={toggleMenu}>Desarrollo de software</Link></li>
									<li> <Link to="/hosting-correo" onClick={toggleMenu}>Hosting y correo</Link></li>
									<li> <Link to="/gestion-web" onClick={toggleMenu}>Administración de sitios</Link></li>
									<li> <Link to="/auditoria" onClick={toggleMenu}>Auditoría web</Link></li>
                                </ul>
                            	)}                                                           
                            </li>
							<li><Link to='/cotizador' onClick={toggleMenu}>COTIZADOR </Link></li>
                            <li><Link to='/nosotros'>NOSOTROS </Link> </li>  
                            <li><Link to='/blog' >BLOG </Link>  </li>
                            <li><Link to="/contacto"  onClick={toggleMenu}>CONTACTO </Link></li>
                        </ul>
                   </div>

                   </div>
                </div>                
            )}
     <Outlet />
    
    </>
  )
}
