import {BrowserRouter, Routes, Route, useLocation } from 'react-router-dom'
import { useLayoutEffect } from 'react'
import Header from './components/Header'
import Home from './pages/Home'
import PaginaWeb from './pages/service/PaginaWeb'
import Contacto from './pages/Contacto'
import Cotizador from './pages/Cotizador'
import TiendaEnLinea from './pages/service/TiendaEnLinea'
import Auditoria from './pages/service/Auditoria'
import DesarrolloWeb from './pages/service/DesarrolloWeb'
import GestionWeb from './pages/service/GestionWeb'
import Hosting from './pages/service/Hosting'
import Nosotros from './pages/Nosotros'
import EnviarCorreo from './components/enviarCorreo'
import Prueba from './pages/Prueba'
import AvisoPrivacidad from './pages/AvisoPrivacidad'
import Blog from './pages/Blog'
import Portafolio from './pages/Portafolio'

import Analytics from './pages/blogs/Analytics'
import CertificadoSSL from './pages/blogs/CertificadoSSL'
import Herramientas from './pages/blogs/Herramientas'
import ProtegeSitio from './pages/blogs/ProtegeSitio'

import SEO from './pages/blogs/SEO'
import Servidores from './pages/blogs/Servidores'
import Tendencias from './pages/blogs/Tendencias'
import Velocidad from './pages/blogs/Velocidad'


const Wrapper = ({children}) =>{
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
}

function App() {

  return (
    <>    
    <BrowserRouter>
    <Wrapper>
        <Routes>
            <Route path="/" element={<Header />}>
                <Route index element={<Home />} />
                <Route path="pagina-web" element={<PaginaWeb />} />
                <Route path="contacto" element={<Contacto />} />
                <Route path="cotizador" element={<Cotizador />} />
                <Route path="nosotros" element={<Nosotros />} />
                <Route path="tienda-en-linea" element={<TiendaEnLinea />} />
                <Route path="auditoria" element={<Auditoria />} />
                <Route path="desarrollo-web" element={<DesarrolloWeb />} />
                <Route path="gestion-web" element={<GestionWeb />} />
                <Route path="hosting-correo" element={<Hosting />} />
              
                <Route path="portafolio" element={<Portafolio />} />
                <Route path="aviso-de-privacidad" element={<AvisoPrivacidad />} />

                <Route path="blog" element={<Blog />} />

                <Route path="google-analytics" element={<Analytics />} />
                <Route path="certificado-de-seguridad" element={<CertificadoSSL />} />
                <Route path="mejores-herramientas" element={<Herramientas />} />
                <Route path="protege-tu-sitio-web" element={<ProtegeSitio />} />
                <Route path="seo" element={<SEO />} />
                <Route path="tipos-de-servidores" element={<Servidores />} />
                <Route path="tendencias-2025" element={<Tendencias />} />
                <Route path="velocidad-de-carga" element={<Velocidad />} />
                
            </Route>  
            <Route path="enviar-correo" element={<EnviarCorreo />} /> 
            <Route path="prueba" element={<Prueba />} />  
        </Routes>
      </Wrapper>
    </BrowserRouter>
  

    </>


  );
}

export default App;
