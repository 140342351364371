import React from 'react'
import { Outlet, Link } from "react-router-dom";

import '../css/footer.css'
export default function Footer() {
  return (
    <>
    <section class="footer">

        <div className='container'>
            <div className='footer_content'>
                <div className='footer_main_box'>
                    <div style={{ textTransform:'uppercase'}}>
                        <h2>universepage</h2>
                    </div>  
                    <p>Somos una empresa especializada en el desarrollo web, ofrecemos soluciones personalizadas, modernas y funcionales que destacan la identidad única de cada proyecto.</p>                
                    <div className='social'>
                        <div className='social_icon'><i class="fi fi-brands-facebook"></i></div>
                        <div className='social_icon'><i class="fi fi-brands-instagram"></i></div>
                        <div className='social_icon'><i class="fi fi-brands-whatsapp"></i></div>
                    </div>                                        
                    <Link to="/" className="links_menu" >Términos y condiciones </Link>
                    <Link to="/aviso-de-privacidad" className="links_menu" >Aviso de privacidad </Link>  
                    
                </div>
                <div className='footer_container'>
                    <div className='footer_box'>                                               
                        <h3>Menú</h3>
                        <Link to="/">Inicio</Link>
                        <Link to="/nosotros">Nosotros</Link>
                        <Link to="/">Servicios</Link>
                        <Link to="/cotizador">Cotizador</Link>
                        <Link to="/contacto">Contacto</Link>                                                                                                 
                    </div>
                    <div className='footer_box'>                                          
                        <h3>Servicios</h3>
                        <Link to="/pagina-web">Página web</Link>
                        <Link to="/tienda-en-linea">Tienda en linea</Link>
                        <Link to="/auditoria">Auditoría web</Link>
                        <Link to="/contacto">Soporte</Link>                                                
                    </div>
                    <div className='footer_box'>                                          
                        <h3>Soluciones</h3>                         
                        <Link to="/hosting-correo">Hosting y Correo</Link>
                        <Link to="/desarrollo-web">Dominios</Link>
                        <Link to="/gestion-web">Desarrollo de software</Link>                          
                        <Link to="/auditoria">Gestion de contenido web</Link>                                                
                    </div>
                </div>
                
            </div>
            <div className='footer_copyright'>
            <div className='container'>
                <div>
                    <label>Copyright © 2024 UNIVERSEPAGE - Soluciones Web </label>
            
                </div>
            
            </div>
        </div>
            
        </div>
       

    </section>
    <Outlet />
    </>
  )
}
