import React, {useState} from 'react'
import axios from 'axios';
import '../../css/pagina_web.css'
import html from '../../assets/images/html.png'
import css from '../../assets/images/css.png'
import js from '../../assets/images/js.png'
import react from '../../assets/images/react.png'
import node from '../../assets/images/node.png'
import postgresql from '../../assets/images/postgresql.png'
import mysql from '../../assets/images/mysql.png'
import auditoriaweb from '../../assets/images/auditoria.png'
import auditoria from '../../assets/images/auditoria.jpg'
import Footer from '../../components/Footer'
export default function Auditoria() {
	const [nombre, setNombre] = useState('');
	const [empresa, setEmpresa] = useState('');
	const [telefono, setTelefono] = useState('');
    const [servicio, setServicio] = useState('');
	const [email, setEmail] = useState('');
	const [mensaje, setMensaje] = useState('');
	const [aceptoPoliticas, setAceptoPoliticas] = useState(true);

	const [alert, setAlert] = useState(false);

	const enviarCorreo = async () => {

        try {
            await axios.post('https://3cwma7t8s4.execute-api.us-east-1.amazonaws.com/prod/webservice', {
                nombre,
                empresa,
                telefono,
				servicio,
                email,
                mensaje,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    // Agrega otros encabezados si son necesarios
                },
            });
    
            // Limpiar campos después de enviar el correo
            setNombre('');
            setEmpresa('');
            setTelefono('');
			setServicio('');
            setEmail('');
            setMensaje('');
    
            setAlert(true);
        } catch (error) {
            console.error('Error al enviar el correo:', error);
            alert('Error al enviar el correo');
        }
    };
  return (
    <>
    <section className='headers'>
        <div className='container'>
            <div className='title'>
                <h1>Auditoría</h1>
                <p>Nuestro propósito es lo que nos hace levantarnos cada mañana, <br/> y esforzarnos por ofrecer un futuro más digital. </p>
            </div>
        </div>
    </section>
    <section>
      <div className='container'>

          <div class="content_about">
              <div class="about_img">
                  <img src={auditoriaweb} alt='About univesepage' />
              </div>
              <div class="about_text_box">
                  <div class="about_text">
                      <h2 style={{textAlign:'initial'}}>TRANSFORMANDO IDEAS EN SOLUCIONES DIGITALES</h2>
                      <h4>AUDITORIÍA</h4>
                      <p>En el mundo digital en constante evolución, es esencial asegurarse de que tu presencia en línea esté optimizada para alcanzar el máximo potencial. Nuestra auditoría web integral es el primer paso hacia una estrategia en línea más efectiva y exitosa.</p>
                      {
                          /*
                          <!--<p>Descubre nuestro equipo experto en el desarrollo y diseño web.</p>-->
                          */
                      }
                      
                      <a href="#services" class="btn_about" aria-label="categorias universepage">Ver más</a>
                  </div>
                  
              </div>
          </div>
      </div>
  </section>

  <section class="services" id='services'>
		<div class="container">
			<div class="">
				<h2>Auditoría web integral</h2>
                <p>En el mundo digital en constante evolución, es esencial asegurarse de que tu presencia en línea esté optimizada para alcanzar el máximo potencial. Nuestra auditoría web integral es el primer paso hacia una estrategia en línea más efectiva y exitosa.</p>
			</div>
			
			<div class="services_list">
				<div class="box_service">
					<div class="service_title">
						<div class="service_icon"> <i class="fa-solid fa-chart-line"></i> </div>
						<div>Análisis de Rendimiento</div>
					</div>
					<div class="service_text">
                        <p>Evaluamos el rendimiento de tu sitio web para garantizar una carga rápida y una experiencia de usuario óptima. Identificamos y abordamos posibles cuellos de botella que podrían afectar la velocidad y la eficiencia.</p>
					</div>		
				</div>

				<div class="box_service">
					<div class="service_title">
						<div class="service_icon"> <i class="fa-solid fa-eye"></i></div>
						<div>Visibilidad en Motores de Búsqueda</div>
					</div>
					<div class="service_text">
						<p>Analizamos la estructura de tu sitio, las palabras clave y otros factores cruciales para mejorar tu clasificación en los motores de búsqueda. Te proporcionamos recomendaciones específicas para aumentar la visibilidad en línea. </p>
					</div>
				</div>
				<div class="box_service">
					<div class="service_title">
						<div class="service_icon"><i class="fa-solid fa-shield-halved"></i></div>
						<div>Seguridad y Protección</div>
					</div>
					<div class="service_text">
						<p>Realizamos una revisión exhaustiva de la seguridad de tu sitio web, identificando posibles vulnerabilidades y proporcionando soluciones para garantizar la protección de datos y la confianza de tus usuarios.</p>
					</div>

				</div>	
			</div>
			<div class="services_list">
				<div class="box_service">
					<div class="service_title">
						<div class="service_icon"> <i class="fa-solid fa-wand-magic"></i></div>
						<div>Experiencia del Usuario (UX)</div>
					</div>
					<div class="service_text">
						<p>Una buena experiencia del usuario es fundamental. Evaluamos la navegación, el diseño y la usabilidad de tu sitio para garantizar que los visitantes encuentren lo que necesitan de manera rápida y eficiente.</p>
					</div>		
				</div>
				<div class="box_service">
					<div class="service_title">
						<div class="service_icon"> <i class="fa-solid fa-magnifying-glass-chart"></i></div>
						<div>Analítica Web</div>
					</div>
					<div class="service_text">
						<p>Utilizamos herramientas analíticas avanzadas para comprender el comportamiento de tus usuarios. Esto proporciona información valiosa para tomar decisiones informadas y ajustar estrategias según las tendencias del tráfico.</p>
					</div>
				</div>
				<div class="box_service">
					<div class="service_title">
						<div class="service_icon"> <i class="fa-solid fa-arrows-up-to-line"></i></div>
						<div>Adaptabilidad y Crecimiento</div>
					</div>
					<div class="service_text">
						<p>Diseñamos nuestro servicio para ser escalable, asegurándonos de que su sitio web pueda crecer y evolucionar según las necesidades de su negocio. Estamos aquí para apoyarle en cada paso del camino.</p>
					</div>
				</div>	
			</div>
		</div>
	</section>
    <section>
		<div class="container">
			<div class="contact">
				<div class="contact_info">
                    <img src={auditoria} alt='About univesepage' />
					<div>
						<p><i class="fi fi-rr-angle-circle-right"></i> Mejora en el rendimiento y velocidad del sitio.</p>
                        <p><i class="fi fi-rr-angle-circle-right"></i> Aumento de la visibilidad en los motores de búsqueda.</p>
                        <p><i class="fi fi-rr-angle-circle-right"></i> Reforzamiento de la seguridad en línea.</p>
                        <p><i class="fi fi-rr-angle-circle-right"></i> Optimización de la experiencia del usuario.</p>
                        <p><i class="fi fi-rr-angle-circle-right"></i> Toma de decisiones basada en datos analíticos.</p>
					</div>
					

				</div>
				<div class="formulario">
					<div>
						<h2>Envíanos tus dudas o comentarios</h2>
						<p>Completa el formulario de contacto a continuación y cuéntanos lo que necesitas. Estaremos encantados de responder a tus preguntas, proporcionarte asistencia personalizada o brindarte más detalles sobre nuestros servicios.</p>
					</div>
					{alert &&
						<div className='alertMessage'>
							<label>¡Tu formulario ha sido enviado con éxito!</label>
							<p> Nuestro equipo está revisando la información, un agente se pondrá en contacto contigo pronto.</p>
						</div>
					}
					

					<div class="form-group">
						<input
							type="text"
							placeholder="Tu nombre"
							value={nombre}
							onChange={(e) => setNombre(e.target.value)}
						/>
						<input
							type="text"
							placeholder="Nombre de tu empresa (opcional)"
							value={empresa}
							onChange={(e) => setEmpresa(e.target.value)}
						/>
						
					</div>
					<div class="form-group">
						<input type='text'
							value={telefono}
							onChange={(e) => setTelefono(e.target.value)} 
							placeholder="Teléfono" />

						<select value={servicio}
							onChange={(e) => setServicio(e.target.value)}>
							<option>¿Qué servicio te interesa?</option>
							<option>Página web</option>
							<option>Tienda en línea</option>
							<option>Desarrollos web personalizados</option>
							<option>Dominio, Hosting y Correo</option>
							<option>Gestión de contenido web</option>
							<option>Auditoría web</option>
							<option>Otro tema</option>
							<option>No lo tengo definido</option>								
						</select>
					</div>
					<div>
						<input type="email"
							placeholder="Email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							/>
					</div>
					<div>
						<textarea 
							placeholder="¿Cómo podemos ayudarte?"
							value={mensaje}
							onChange={(e) => setMensaje(e.target.value)}
							>
						</textarea>
					</div>
					<div className='term-cond'>
						<input type="checkbox" 
						name=""
						checked={aceptoPoliticas}
						onChange={() => setAceptoPoliticas(!aceptoPoliticas)}
						/> Acepto el aviso legal y las política de privacidad. 
					</div>
					<div class="">
						
						<button
							class="btn_enviar"
							disabled={!aceptoPoliticas}
							onClick={enviarCorreo}
							>
							Enviar
							</button>

					</div>
				</div>
			</div>
		</div>
	</section>

    <section className='herramientas_tecnologias'>
      <div className='container'>
          <div className='herramientas'>
              <div className='herramientas_text'>
                  <h4>HERRAMIENTAS Y TECNOLOGIAS</h4>
                  <div className='herramientas_text_content'>
                      <p>Nuestras habilidades y experiencia nos permiten ofrecer soluciones web a través de diversas tecnologías para el desarrollo de software, atractivas, robustas, seguras y escalables.</p>
                  </div>
                 

              </div>
              <div className='herramientas_img'>
                  <div className='herramientas_box'>
                      <img src={html} alt='html' />
                      <p>HTML</p>
                  </div>
                  <div className='herramientas_box'>
                      <img src={css} alt='css' />
                      <p>CSS</p>
                  </div>
                  <div className='herramientas_box'>
                      <img src={js} alt='java script' />
                      <p>JavaScript</p>
                  </div>
                  <div className='herramientas_box'>
                      <img src={react} alt='react' />
                      <p>React</p>
                  </div>
                  <div className='herramientas_box'>
                      <img src={node} alt='Node Js' />
                      <p>Node JS</p>
                  </div>
                  <div className='herramientas_box'>
                      <img src={postgresql} alt='PostgreSQL' />
                      <p>PostgreSQL</p>
                  </div>
                  <div className='herramientas_box'>
                      <img src={mysql} alt='mySQL' />
                      <p>MySQL</p>
                  </div>
              </div>
              <div className='herramientas_text'>                    
                  <div className='herramientas_text_content'>
                      <p></p>
                  </div>
              </div>
          </div>

      </div>
    </section>
    
    <Footer />
  </>
  )
}
