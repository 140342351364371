import React, {useState} from 'react'
import '../css/portafolio.css';
import { Outlet } from 'react-router-dom';

import mobile1 from '../assets/images/categorias/mobile1.jpg';
import camera1 from '../assets/images/categorias/camera1.jpg';
import watch1 from '../assets/images/categorias/watch1.jpg';
import shoe1 from '../assets/images/categorias/shoe1.jpg';
import headphone1 from '../assets/images/categorias/headphone1.jpg';

import mobile2 from '../assets/images/categorias/mobile2.jpg';
import camera2 from '../assets/images/categorias/camera2.jpg';
import watch2 from '../assets/images/categorias/watch2.jpg';
import shoe2 from '../assets/images/categorias/shoe2.jpg';
import headphone2 from '../assets/images/categorias/headphone2.jpg';

import mobile3 from '../assets/images/categorias/mobile3.jpg';
import camera3 from '../assets/images/categorias/camera3.jpg';
import watch3 from '../assets/images/categorias/watch3.jpg';
import shoe3 from '../assets/images/categorias/shoe3.jpg';
import headphone3 from '../assets/images/categorias/headphone3.jpg';

import mobile4 from '../assets/images/categorias/mobile4.jpg';
import watch4 from '../assets/images/categorias/watch4.jpg';
import shoe4 from '../assets/images/categorias/shoe4.jpg';
import headphone4 from '../assets/images/categorias/headphone4.jpg';

import mobile5 from '../assets/images/categorias/mobile5.jpg';
export default function Portafolio() {

      // Estado para el filtro activo
  const [filter, setFilter] = useState('All');

  // Manejador de clic para cambiar el filtro
  const handleFilterClick = (filter) => {
    setFilter(filter);
  };

  // Productos para renderizar (puede ser un array o objetos)
  const products = [
    { category: 'mobile', src: mobile1 },
    { category: 'camera', src: camera1 },
    { category: 'watch', src: watch1 },
    { category: 'shoe', src: shoe1 },
    { category: 'headphone', src: headphone1 },
    { category: 'mobile', src: mobile2 },
    { category: 'camera', src: camera2 },
    { category: 'watch', src: watch2 },
    { category: 'shoe', src: shoe2 },
    { category: 'headphone', src: headphone2 },
    { category: 'mobile', src: mobile3 },
    { category: 'camera', src: camera3 },
    { category: 'watch', src: watch3 },
    { category: 'shoe', src: shoe3 },
    { category: 'headphone', src: headphone3 },
    { category: 'mobile', src: mobile4 },
    { category: 'watch', src: watch4 },
    { category: 'shoe', src: shoe4 },
    { category: 'headphone', src: headphone4 },
    { category: 'mobile', src: mobile5 },
  ];


  return (
    <>
        <section className='headers'>
            <div className='container'>
                <div className='title'>
                    <h1>Portafolio</h1>
                    <p>Nuestro propósito es lo que nos hace levantarnos cada mañana, <br/> y esforzarnos por ofrecer un futuro más digital. </p>
                </div>
            </div>
        </section>
     <section className='categorias'>
        <ul>
          <li
            className={`list ${filter === 'All' ? 'active' : ''}`}
            onClick={() => handleFilterClick('All')}
          >
            All
          </li>
          <li
            className={`list ${filter === 'mobile' ? 'active' : ''}`}
            onClick={() => handleFilterClick('mobile')}
          >
            mobile
          </li>
          <li
            className={`list ${filter === 'camera' ? 'active' : ''}`}
            onClick={() => handleFilterClick('camera')}
          >
            camera
          </li>
          <li
            className={`list ${filter === 'watch' ? 'active' : ''}`}
            onClick={() => handleFilterClick('watch')}
          >
            watch
          </li>
          <li
            className={`list ${filter === 'shoe' ? 'active' : ''}`}
            onClick={() => handleFilterClick('shoe')}
          >
            shoe
          </li>
          <li
            className={`list ${filter === 'headphone' ? 'active' : ''}`}
            onClick={() => handleFilterClick('headphone')}
          >
            headphone
          </li>
        </ul>
        <div className='product'>
          {products
            .filter((product) => filter === 'All' || product.category === filter)
            .map((product, index) => (
              <div key={index} className={`itemBox ${product.category}`}>
                <img src={product.src} alt='' />
              </div>
            ))}
        </div>
      </section>
      <Outlet />
      </>
  )
}
