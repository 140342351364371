import React from 'react'
import { Outlet, Link } from "react-router-dom";
import '../css/blog.css'
import ssl from'../assets/images/blog/Certificado de seguridad.png'
import tendencia from'../assets/images/blog/Tendencias.png'
import seo from '../assets/images/blog/seo.png'
import protegeSitio from '../assets/images/blog/protegeSitio.png'
import servidor from '../assets/images/blog/servidor.png'
import velocidad from '../assets/images/blog/velocidad.png'
import herramientas from '../assets/images/blog/herramientas.png'
import analytics from '../assets/images/blog/analytics.png'

import Footer from '../components/Footer'
export default function Blog() {
  return (
   <>
    <section className='headers'>
        <div className='container'>
            <div className='title'>
                <h1>Blog</h1>
                <p>Conoce las mejores prácticas, tendencias y recursos que harán <br/> destacar a tu negocio en línea.</p>
            </div>
        </div>
    </section>
    <section className='blog'>
      <div className='container'>
        <div className='blog-container'>

       
          <div className='blog-content'>
          <Link to="/tendencias-2025">
            <img src={tendencia} alt='ssl'/>
            <div className='blog-box'>
                <div className='blog-box-text'>
                    <h2>5 Tendencias en Diseño Web para 2025</h2>
                </div>
                <div className='blog-fecha'>
                    <label>31 de octubre 2024</label>
                </div>
            </div>     
            </Link>       
          </div>
        
          <div className='blog-content'>
          <Link to="/mejores-herramientas">
            <img src={herramientas} alt='ssl'/>
            <div className='blog-box'>
                <div className='blog-box-text'>
                    <h2>Las mejores herramientas para emprendedores digitales</h2>
                </div>
                <div className='blog-fecha'>
                    <label>31 de octubre 2024</label>
                </div>
            </div> 
            </Link>
          </div>
          <div className='blog-content'>
          <Link to="/certificado-de-seguridad">
            <img src={ssl} alt='ssl'/>
            <div className='blog-box'>
                <div className='blog-box-text'>
                    <h2>¿Qué es y para qué sirve un certificado de seguridad SSL?</h2>
                </div>
                <div className='blog-fecha'>
                    <label>31 de octubre 2024</label>
                </div>
            </div> 
            </Link>                        
          </div>
          
          

          <div className='blog-content'>
          <Link to="/tipos-de-servidores">
            <img src={servidor} alt='ssl'/>
            <div className='blog-box'>
                <div className='blog-box-text'>
                    <h2>¿Cómo elegir el mejor hosting para tu página web o tienda en línea?</h2>
                </div>
                <div className='blog-fecha'>
                    <label>31 de octubre 2024</label>
                </div>
            </div> 
            </Link>         
          </div>
          
          <div className='blog-content'>
          <Link to="/protege-tu-sitio-web">
            <img src={protegeSitio} alt='ssl'/>
            <div className='blog-box'>
                <div className='blog-box-text'>
                <h2>Consejos básicos de seguridad</h2>
                </div>
                <div className='blog-fecha'>
                    <label>31 de octubre 2024</label>
                </div>
            </div> 
            </Link>        
          </div>
          <div className='blog-content'>
          <Link to="/seo">
            <img src={seo} alt='ssl'/>
            <div className='blog-box'>
                <div className='blog-box-text'>
                    <h2>SEO para pequeños negocios: Cómo mejorar tu visibilidad en Google</h2>
                </div>
                <div className='blog-fecha'>
                    <label>31 de octubre 2024</label>
                </div>
            </div> 
            </Link> 
          </div>
         
          <div className='blog-content'>
          <Link to="/velocidad-de-carga">
            <img src={velocidad} alt='ssl'/>
            <div className='blog-box'>
                <div className='blog-box-text'>
                    <h2>Cómo mejorar la velocidad de carga de tu página web</h2>
                </div>
                <div className='blog-fecha'>
                    <label>31 de octubre 2024</label>
                </div>
            </div> 
            </Link> 
          </div>
          
          <div className='blog-content'>
          <Link to="/google-analytics">
            <img src={analytics} alt='ssl'/>
            <div className='blog-box'>
                <div className='blog-box-text'>
                    <h2>Guía rápida para integrar Google Analytics en tu sitio web</h2>
                </div>
                <div className='blog-fecha'>
                    <label>31 de octubre 2024</label>
                </div>
            </div>  
            </Link>                
          </div>

          
        </div>
      </div>
    </section>
    <Footer />
    <Outlet />
    </>
  )
}
