import React, {useState}  from 'react'
import axios from 'axios';
import '../../css/pagina_web.css'
import '../../css/desarrollo.css'
import desarrollo from '../../assets/images/desarrollo.jpg'
import html from '../../assets/images/html.png'
import css from '../../assets/images/css.png'
import js from '../../assets/images/js.png'
import react from '../../assets/images/react.png'
import node from '../../assets/images/node.png'
import postgresql from '../../assets/images/postgresql.png'
import mysql from '../../assets/images/mysql.png'
import auditoria from '../../assets/images/auditoria.jpg'
import { Outlet, Link } from "react-router-dom";
import Footer from '../../components/Footer'

export default function DesarrolloWeb() {
    const [nombre, setNombre] = useState('');
	const [empresa, setEmpresa] = useState('');
	const [telefono, setTelefono] = useState('');
    const [servicio, setServicio] = useState('');
	const [email, setEmail] = useState('');
	const [mensaje, setMensaje] = useState('');
	const [aceptoPoliticas, setAceptoPoliticas] = useState(true);

	const [alert, setAlert] = useState(false);
    //await axios.post('http://localhost:3001/enviar-correo', {

	const enviarCorreo = async () => {

        try {
            await axios.post('https://3cwma7t8s4.execute-api.us-east-1.amazonaws.com/prod/webservice', {
                nombre,
                empresa,
                telefono,
				servicio,
                email,
                mensaje,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    // Agrega otros encabezados si son necesarios
                },
            });
		
    		
            // Limpiar campos después de enviar el correo
            setNombre('');
            setEmpresa('');
            setTelefono('');
			setServicio('');
            setEmail('');
            setMensaje('');
    
            setAlert(true);
        } catch (error) {
            console.error('Error al enviar el correo:', error);
            alert('Error al enviar el correo');
        }
    };

    
  return (

    <>
    <section className='headers'>
        <div className='container'>
            <div className='title'>
                <h1>Desarrollos web</h1>
                <p>Nuestro propósito es lo que nos hace levantarnos cada mañana, <br/> y esforzarnos por ofrecer un futuro más digital. </p>
            </div>
        </div>
    </section>
    <section>
      <div className='container'>
          <div class="content_about">
              <div class="about_img">
                  <img src={desarrollo} alt='About univesepage' />
              </div>
              <div class="about_text_box">
                  <div class="about_text">
                      <h2 style={{textAlign:'initial'}}>CREAMOS LAS SOLUCIONES</h2>
                      <h4>Desarrollo de software</h4>
                      <p>En nuestra empresa de desarrollo de software, ofrecemos un servicio integral de desarrollo web para satisfacer todas sus necesidades.</p>
                      <p>Entendemos que cada proyecto web es único y merece una solución a medida que refleje la singularidad de tu visión y objetivos. Nuestro servicio de Desarrollo Web Personalizado está diseñado para aquellos que buscan más que presencia en línea.</p>                      
                      
                      <a href="#categorias" class="btn_about"  aria-label="categorias universepage">Ver categorias</a>
                  </div>                  
              </div>
          </div>
      </div>
  </section>
<section>
    <div className='container'>
        <div class="services_list">				
            <div class="box_service">
                <Link to="/pagina-web">
                    <div class="service_title">
                        <div class="service_icon"> <i class="fa-regular fa-file-code" ></i></div>
                        <div>Desarrollos FRONT - BACKEND</div>
                    </div>
                    <div class="service_text">
                        <p> Somos especialistas en desarrollos de software, desde un sistema de gestión, plataforma online, hasta casi cualquier desarrollo tecnológico que tu empresa necesite. </p>
                    </div>	
                </Link>	
            </div>			
            <div class="box_service">
                <Link to="/tienda-en-linea">
                    <div class="service_title">
                        <div class="service_icon"> <i class="fa-solid fa-cart-shopping"></i></div>
                        <div>Soporte</div>
                    </div>
                    <div class="service_text">
                        <p>Desde problemas técnicos hasta consultas generales, nuestro equipo está aquí para ayudarlo en cualquier aspecto relacionado con su plataforma en línea.</p>
                        
                    </div>
                </Link>	
            </div>
            <div class="box_service">
                <Link to="/desarrollo-web">
                    <div class="service_title">
                        <div class="service_icon"> <i class="fa-solid fa-laptop-code"></i></div>
                        <div>Integraciones</div>
                    </div>
                    <div class="service_text">
                        <p>Las integraciones web más solicitadas pueden variar dependiendo de las necesidades específicas de cada empresa o proyecto. Las integraciones web más populares y solicitadas incluyen: Integración de redes sociales, Integración de pago, Integración de chat con IA </p>
                    </div>
                </Link>	
            </div>	
        </div>
    </div>
</section>
    <section>
		<div class="container">
			<div class="contact">
				<div class="contact_info">
                    <img src={auditoria} alt='About univesepage' />
					<div>
						<p><i class="fi fi-rr-angle-circle-right"></i> Soluciones centradas en el cliente</p>
                        <p><i class="fi fi-rr-angle-circle-right"></i>  Integración de funcionalidades específicas </p>
                        <p><i class="fi fi-rr-angle-circle-right"></i> Implementación de metodologías ágiles</p>
					</div>					
				</div>
                <div class="formulario">
						<div>
							<h2>Envíanos tus dudas o comentarios</h2>
							<p>Completa el formulario de contacto a continuación y cuéntanos lo que necesitas. Estaremos encantados de responder a tus preguntas, proporcionarte asistencia personalizada o brindarte más detalles sobre nuestros servicios.</p>
						</div>
						{alert &&
							<div className='alertMessage'>
								<label>¡Tu formulario ha sido enviado con éxito!</label>
								<p> Nuestro equipo está revisando la información, un agente se pondrá en contacto contigo pronto.</p>
							</div>
						}						
						<div class="form-group">
							<input
								type="text"
								placeholder="Tu nombre"
								value={nombre}
								onChange={(e) => setNombre(e.target.value)}
							/>
							<input
								type="text"
								placeholder="Nombre de tu empresa (opcional)"
								value={empresa}
								onChange={(e) => setEmpresa(e.target.value)}
							/>
							
						</div>
						<div class="form-group">
							<input type='text'
								value={telefono}
								onChange={(e) => setTelefono(e.target.value)} 
								placeholder="Teléfono" />

							<select value={servicio}
								onChange={(e) => setServicio(e.target.value)}>
								<option>¿Qué servicio te interesa?</option>
								<option>Página web</option>
								<option>Tienda en línea</option>
								<option>Desarrollos web personalizados</option>
								<option>Dominio, Hosting y Correo</option>
								<option>Gestión de contenido web</option>
								<option>Auditoría web</option>
								<option>Otro tema</option>
								<option>No lo tengo definido</option>								
							</select>
						</div>
						<div>
							<input type="email"
								placeholder="Email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								/>
						</div>
						<div>
							<textarea 
								placeholder="¿Cómo podemos ayudarte?"
								value={mensaje}
								onChange={(e) => setMensaje(e.target.value)}
								>
							</textarea>
						</div>
						<div className='term-cond'>
							<input type="checkbox" 
							name=""
							checked={aceptoPoliticas}
							onChange={() => setAceptoPoliticas(!aceptoPoliticas)}
							/> Acepto el aviso legal y las política de privacidad. 
						</div>
						<div class="">
							
							<button
								class="btn_enviar"
								disabled={!aceptoPoliticas}
								onClick={enviarCorreo}
								>
								Enviar
								</button>

						</div>
					</div>
					

				</div>
		
		</div>
	</section>




    <section className='herramientas_tecnologias'>
      <div className='container'>
          <div className='herramientas'>
              <div className='herramientas_text'>
                  <h4>HERRAMIENTAS Y TECNOLOGIAS</h4>
                  <div className='herramientas_text_content'>
                      <p>Las herramientas y lenguajes que usamos dependerán en gran medida de los servicios específicos que necesitas y de la tecnología que mejor se adapte a tus requerimientos. Aquí tienes una lista general de herramientas, lenguajes y tecnologías que podríamos usar para tu desarrollo web personalizado:</p>
                  </div>
                 

              </div>
              <div className='herramientas_img'>
                  <div className='herramientas_box'>
                      <img src={html} alt='html' />
                      <p>HTML</p>
                  </div>
                  <div className='herramientas_box'>
                      <img src={css} alt='css' />
                      <p>CSS</p>
                  </div>
                  <div className='herramientas_box'>
                      <img src={js} alt='java script' />
                      <p>JavaScript</p>
                  </div>
                  <div className='herramientas_box'>
                      <img src={react} alt='react' />
                      <p>React</p>
                  </div>
                  <div className='herramientas_box'>
                      <img src={node} alt='Node Js' />
                      <p>Node JS</p>
                  </div>
                  <div className='herramientas_box'>
                      <img src={postgresql} alt='PostgreSQL' />
                      <p>PostgreSQL</p>
                  </div>
                  <div className='herramientas_box'>
                      <img src={mysql} alt='mySQL' />
                      <p>MySQL</p>
                  </div>
              </div>
              <div className='herramientas_text'>                    
                  <div className='herramientas_text_content'>
                      <p></p>
                  </div>
              </div>
          </div>

      </div>
    </section>
    <Outlet />
    <Footer />
  </>
  )
}
